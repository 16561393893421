@mixin hiddenScrollBar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.container {
  margin: 0 auto;
  .actions {
    background: #edeef0;
    border: solid #d7d7d9;
    border-width: 0px 1px 1px 0px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12);
  }
  .customersList {
    background: #edeef0;
    border-right: 1px solid #d7d7d9;
    overflow-y: scroll;
    height: calc(100vh - 195px);
    @include hiddenScrollBar;
    &.lightCustomerList {
      background-color: #f6f6f8;
    }
  }
  .employeesListWrapper {
    border-radius: 4px;
    overflow: hidden;
  }
  .employeesList {
    height: calc(100vh - 147px);
    overflow-y: scroll;
    overflow-anchor: none;
    background: #dcdee0;
    @include hiddenScrollBar;
    &.lightEmployeeList {
      background: #f6f6f8;
    }
  }
}
