.warningContainer {
  box-shadow: 0px 0px 2px 4px #fde94f;
  border-radius: 4px;
}
:global {
  .MuiToggleButtonGroup-grouped {
    &.Mui-disabled {
      border-left: 1px solid #D7D7D9 !important;
    }
  }
}

.swapAlert {
  background-color: #F0F0FF !important;
  padding: 8px !important;
  border-radius: 6px !important;

  :global {
    .MuiAlert-root {
      background-color: #F0F0FF;
    }

    .MuiSvgIcon-root {
      color: #000000;
      & path {
        fill: #000000;
      }
    }
  }
}

.swapInfoIcon {
  height: 17px;
  width: 17px;
}

.swapTooltip {
  padding: 0 !important;
  background-color: transparent !important;
}

.swapTimesFetchButton {
  :global {
    .MuiFormControl-root {
      & > .MuiFormLabel-root {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &.swapTimesFetchButtonDisabled {
    pointer-events: none;

    :global {
      .MuiFormControl-root {
        & > label {
          color: rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
}
