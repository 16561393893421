:global {
  .MuiDialogContent-root {
    background-color: white !important;
  }
}

.container {
  height: 470px;
  .focusTitle {
    color: #2E3033;
    font-family: 'Fielmann-Sans';
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 24px;
  }
  .focusToggleButton {
    text-transform: capitalize;
    &:global(.Mui-selected) {
      color: white;
      background-color: #5D8EA3;
      font-weight: 500;

      &:hover {
        background-color: rgba(93,142,163, 0.85);
      }
    }
  }
  .servicesTitle {
    color: #2E3033;
    font-family: 'Fielmann-Sans';
    font-size: 14px;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
    margin: 24px 0 12px 0;
  }
  .focusCounter {
    margin-left: 6px;
    background-color: black;
    color: white;
    font-weight: 400;
    border-radius: 50%;
    width: 18px;
    &.selectedCounter {
      background-color: white;
      color: black;
    }
  }
  .checkbox {
    margin-top: 12px;
  }
  .closeButton {
    padding: 0;
  }
  .selectButton {
    font-size: 14px;
    line-height: 16px;
  }
}
