.container {
  background: #F6F6F8;
  min-height: 100vh;
}

.header {
  padding: 12px 0;
  position: fixed;
  top: 0;
  left: 0;
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #E0E0E4;
  background: #FFF;

  .titleContainer {
    margin-left: 4px;
    width: 356px;
  }
  .title {
    color: #000;
    font-family: Fielmann-Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    margin-left: 8px;
  }

  .backIcon {
    width: 22px;
    height: 24px;
  }
}

.searchContainer {
  border-radius: 4px;
  border: 1px solid #E0E0E4;
  background: #FFF;
  margin: 0 16px;
  flex-grow: 1;

  .searchIcon {
    margin-left: 8px;
  }
  .input {
    width: 100%;
    background: #FFF;
  }
  .closeIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    cursor: pointer;
    margin-right: 8px;
    svg {
      fill: black;
    }
  }
}
.searchResultsContainer {
  min-height: calc(100vh - 64px);
  margin-top: 64px;
  padding: 12px 24px 0 24px;
}
.noResultsText {

}

.date {
  color: #898989;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Fielmann-Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500 !important;
  position: relative;
  width: 100%;
  margin-bottom: 12px !important;
  &:after {
    content: '';
    position: absolute;
    top: 9px;
    left: 78px;
    width: calc(100% - 82px);
    display: block;
    border-top: 1px solid #B0B0B0;
  }
}

.cards {
  border-radius: 6px;
  border: 1px solid #DEDEE2;
  background: #FFF;
  width: 100%;
  box-sizing: border-box;
  padding: 18px 14px;
  margin-bottom: 20px;
}

.card {
  width: 100%;
  background: #fff;
  border-left: 1px solid #dedee2;
  border-right: 1px solid #dedee2;
  //border-bottom: 1px solid rgba(217, 217, 217, 0.3);
  box-sizing: border-box;
  padding: 18px 14px;
  position: relative;

  &:after {
    position: absolute;
    bottom: 0;
    width: calc(100% - 28px);
    content: '';
    border-bottom: 1px solid rgba(217, 217, 217, 0.3);
  }
  &.isFirstInDay {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-top: 1px solid #dedee2;
  }
  &.isLastInDay {
    border-bottom: 1px solid #dedee2;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    margin-bottom: 20px;
  }

  &.clickable {
    &:hover {
      cursor: pointer;
    }
  }
  .appointmentIcon {
    margin-top: 4px;
    margin-right: 8px;
    path {
      fill: #994BFE;
    }
  }
  .taskIcon {
    margin-top: 4px;
    margin-right: 8px;
  }
  .walkinIcon {
    margin-top: 4px;
    margin-right: 8px;
  }
  .blockerIcon {
    margin-top: 4px;
    margin-right: 8px;
  }
  .breakIcon {
    margin-top: 4px;
    margin-right: 8px;
  }
  .textContainer {
    flex-grow: 1;
  }
  .editIcon {
    margin-left: 8px;
    cursor: pointer;
  }
  .name {
    color: #2E3033;
    font-family: Fielmann-Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
  .interval {
    margin-top: 10px;
    color: #2E3033;
    font-family: Fielmann-Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;

    span {
      font-weight: 700;
      margin: 0 3px;
    }
  }
  .employee {
    margin-top: 10px;
    color: #2E3033;
    font-family: Fielmann-Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  .note {
    margin-top: 10px;
    color: #6A6B6C;
    font-family: Fielmann-Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  .serviceList {
    align-self: center;
  }

  .services {
    display: flex;
    flex-direction: row;

    .serviceWrapper {
      display: flex;
      width: 24px;
      height: 24px;
      justify-content: center;
      align-items: center;
      border-radius: 80px;
      background: #EAD0FF;
      margin-left: 4px;

      svg {
        transform: scale(0.65);

        path[fill] {
          fill: #000000 !important;
          stroke: none;
        }
        path[stroke] {
          fill: none;
          stroke: #000000 !important;
        }
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
