.container {
  padding-right: 16px;
  border-right: 1px solid #E0E0E4;
  height: 64px;
  align-items: flex-end;
  align-content: baseline;
  justify-content: flex-start;
  flex-wrap: wrap-reverse;
  flex-direction: column;
  flex-grow: 1;
  .item {
    margin-bottom: 6px;
    margin-right: 4px;
    height: 25px;
    justify-content: flex-start;
    &:last-child {
      margin-bottom: 0;
    }
    .icon {
      transform: scale(0.75);
      width: 24px;
      margin-right: -6px;
      fill: #688DA1 !important;

      path {
        fill: #688DA1 !important;
      }
    }
    .title {
      color: #000;
      font-family: 'Fielmann-Sans';
      font-size: 18px;
      font-weight: 500;
      line-height: 18px; /* 100% */
      margin: 0 6px;
    }
    .value {
      color: #000;
      font-family: 'Fielmann-Sans';
      font-size: 18px;
      font-weight: 400;
      line-height: 18px; /* 100% */
      min-width: 10px;
      text-align: center;
    }
  }
}
