.tabsContainer {
  border-radius: 8px;
  padding: 4px;
  background: #F6F6F8;
  justify-content: space-between;
  margin-bottom: 12px;
  :global {
    .MuiToggleButtonGroup-grouped {
      border: 0;
      height: 36px;
      border-radius: 6px !important;
      margin-right: 6px;
      background-color: #FFFFFF;
      color: #2E3033;

      font-family: Fielmann-Sans;
      font-size: 14px;
      line-height: 14px;
      width: 25%;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
        color: black;
      }
      &.Mui-disabled {
        border-left: 1px solid #D7D7D9 !important;
      }
      &.Mui-selected {
        color: white;
        background-color: black;
        font-weight: 500;
      }
    }
  }
  &.lightColors {
    :global {
      .MuiToggleButtonGroup-grouped.Mui-selected {
        color: black;
        background-color: white;
        &:hover {
          background-color: rgba(255, 255, 255, 0.5);
        }
      }
    }
  }
  &.oneTab {
    :global {
      .MuiToggleButtonGroup-grouped {
        width: 100%;
      }
    }
  }
  &.twoTabs {
    :global {
      .MuiToggleButtonGroup-grouped {
        width: 50%;
      }
    }
  }
  &.threeTab {
    :global {
      .MuiToggleButtonGroup-grouped {
        width: 33%;
      }
    }
  }

  .tabBadge {
    height: 6px;
    width: 6px;
    background-color: #5D8EA3;
    border-radius: 50%;
    margin-left: 6px;
    margin-right: -12px;
  }
}
.servicesList {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;

  .serviceButtonText {
    flex-grow: 1;
  }

  .serviceButtonContainer {
    border-radius: 6px !important;
    background: #F4F4F6;

    svg {
      & *[fill] {
        fill: #000000 !important;
      }
      & *:not([fill]) {
        fill: none !important;
      }
      & *[stroke] {
        stroke: #000000 !important;
      }
      & *:not([stroke]) {
        stroke: none !important;
      }
    }

    &.selectedService {
      background-color: black;

      svg {
        & *[fill] {
          fill: #FFFFFF !important;
        }
        & *:not([fill]) {
          fill: none !important;
        }
        & *[stroke] {
          stroke: #FFFFFF !important;
        }
        & *:not([stroke]) {
          stroke: none !important;
        }
      }
    }
  }


  .serviceButtonTextDuration {
    font-size: 12px;
    font-weight: 400;
    font-family: Fielmann-Sans;
  }

  .serviceButtonTextLabel {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
    font-family: Fielmann-Sans;
    font-size: 12px;
    font-weight: 400;
  }

  &.lightColors {
    .serviceButtonContainer {
      svg {
        & *[fill] {
          fill: #5D8EA3 !important;
        }
        & *:not([fill]) {
          fill: none !important;
        }
        & *[stroke] {
          stroke: #5D8EA3 !important;
        }
        & *:not([stroke]) {
          stroke: none !important;
        }
      }
    }
    .selectedService {
      color: white;
      background-color: #5D8EA3;
      .serviceButtonTextLabel {
        font-weight: 500;
      }

      svg {
        & *[fill] {
          fill: #FFFFFF !important;
        }
        & *:not([fill]) {
          fill: none !important;
        }
        & *[stroke] {
          stroke: #FFFFFF !important;
        }
        & *:not([stroke]) {
          stroke: none !important;
        }
      }

      &:hover {
        color: white;
        background-color: rgba(93,142,163, 0.85);
        .serviceButtonTextLabel {
          font-weight: 500;
        }
        svg {
          & *[fill] {
            fill: #FFFFFF !important;
          }
          & *:not([fill]) {
            fill: none !important;
          }
          & *[stroke] {
            stroke: #FFFFFF !important;
          }
          & *:not([stroke]) {
            stroke: none !important;
          }
        }
      }
    }
  }

}
