.renderValue {
  display: inline;
  height: 33px;
  max-width: fit-content;
  text-align: center;
  padding: 6px 12px;
  margin: 0 8px 12px 0 !important;

  border-radius: 80px;
  background: #F6F6F8;
  color: #2E3033;
  font-family: Fielmann-Sans;
  font-size: 14px;
  line-height: 14px;

  cursor: pointer;
  box-sizing: border-box;
}
