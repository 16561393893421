.reloadEpepIcon {
  path {
    fill: #ff0000;
  }
}

.reloadBreaksIcon {
  path {
    fill: #002aff;
  }
}

.epepWithTimer {
  align-items: center;
  opacity: 0.5;
  pointer-events: none;

  > p {
    font-size: 16px;
  }
}

.epepWithoutTimer {

}
