.formWrapper {
  width: 456px;
  height: calc(100vh - 30px);
  overflow-y: scroll;
  position: absolute;
  padding: 16px;
  top: 0;
  left: 0;
  border-radius: 6px !important;
  box-shadow: 0px 24px 48px -12px rgba(14, 24, 41, 0.18) !important;
  box-sizing: content-box;
  z-index: 20;

  .actionIcon {
    width: 18px;
    height: 18px;
  }

  .formTitle {
    color: #2E3033;
    font-family: Fielmann-Sans;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
  }
  .slotsTitle {
    color: #2E3033;
    font-family: Fielmann-Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }
  .slotsItems {
    flex-flow: wrap;
    margin-bottom: 12px;
  }
  .slotsItem {
    display: flex;
    height: 33px;
    width: 58px;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    border-radius: 80px !important;
    margin-right: 0 !important;
    background-color: #F6F6F8;
    font-weight: 400;
  }
  .slotsExpandTitle {
    cursor: pointer;
    text-decoration: underline;
    color: black;
    font-family: Fielmann-Sans;
    font-size: 16px;
    line-height: 16px;
  }

  :global {
    .DialogSection-root {
      .DialogSection-title {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
.slotsPicker {
  margin-top: 8px !important;
  :global {
    .MuiToggleButtonGroup-root {
      grid-template-columns: repeat(auto-fit, minmax(max((100% - 32px) / 4, 100px), 120px));
      grid-column-gap: 8px;
      grid-row-gap: 8px;
    }
    .MuiToggleButtonGroup-grouped {
      margin-right: 0;
      &.Mui-selected {
        background-color: #2E3033;
        color: white;
        font-weight: 500;
      }
    }
  }
}

.detailsTabContainer {
  margin-top: 6px !important;
}

@media all and (max-device-width: 1024px) and (orientation:landscape) {
  .formWrapper {
    height: calc(100dvh - 30px);
  }
}
