.calendar_container {
  height: calc(100vh - 118px);
  width: 100%;
  overflow: scroll;

}

.day_card {
  height: 170px;
  min-width: 146px;
  overflow: hidden;
  margin: 8px 12px;
  padding: 12px 14px;
  border-radius: 4px;
  position: relative;

  &.active {
    background: white;
    box-shadow: 0 4px 4px 0 rgba(73, 74, 78, 0.05);
  }

  &.today {
    border: 1px solid #2E3033;
  }

  &.disabled {
    opacity: 0.2;
  }

  .title {
    color: #2E3033;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
  }

  .title_addon {
    position: absolute;
    right: 12px;
    top: 10px;
  }

  .today_label {
    border-radius: 4px;
    background: #F6F6F8;
    font-family: Manrope;
    font-size: 10px;
    line-height: 1;
    font-style: normal;
    padding: 6px;
  }

  .overview_item {
    margin: 16px 0;
    font-size: 12px;
    line-height: 1;


    .item_value {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 6px;
      line-height: 18px;
    }
  }
}

.error {
  color: #FE3C25;
}

.detailed_overview {
  padding: 12px 14px;

  width: 300px;

  .title {
    color: #2E3033;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 8px;
  }

  .overview_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);


    .overview_item {
      margin: 7px 0;
      font-size: 12px;
      line-height: 1;

      .item_value {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 6px;
        line-height: 18px;
      }
    }
  }
}

.barContainer {
  margin-top: 10px;
  position: relative;

  .barTitle {
    color: #6A6B6C;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    margin-bottom: 28px;
  }

  .barValue {
    position: absolute;
    top: 16px;
    left: 10px;
  }

  .barTotal {
    position: absolute;
    top: 16px;
  }

  .barRow {
    background-color: #EBEBEB;
    height: 13px;
    width: 150px;
    position: relative;
  }
  .barInner {
    position: absolute;
    background-color: #2E3033;
    height: 13px;
    top: 0;
    left: 0;
  }

  &.redBar {
    .barInner {
      background-color: #FE3C25;
    }
  }

  .rowIcon {
    position: absolute;
    right: -11px;
    top: -8px;
  }

  .barIcon {
    position: absolute;
    top: -6px;
  }
}
