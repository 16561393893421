.container {
  border-radius: 8px;
  padding: 4px;
  background: #F6F6F8;
  :global {
    .MuiToggleButtonGroup-grouped {
      border: 0;
      height: 36px;
      border-radius: 6px !important;
      margin-right: 6px;
      color: #2E3033;

      font-family: Fielmann-Sans;
      font-size: 14px;

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        background-color: white;
        color: black;
      }
      &.Mui-selected {
        color: black;
        background-color: white;
        &:hover {
          background-color: white;
          color: black;
        }
      }
      &.Mui-disabled {
        border-left: 1px solid #D7D7D9 !important;
      }
    }
  }
}
