.userAgentWrapperRoot {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .browserIcon {
    height: 130px;
    width: 130px;
  }
}
