.header {
  height: 68px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  .title{
    color: #000;
    font-size: 24px;
    font-weight: 700;
  }

}
