.container {
  .label {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    margin-right: 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .todayLabel {
    display: flex;
    align-items: center;
    font-size: 14px;
    fontFamily: Manrope;
    text-transform: uppercase;
    line-height: 20px;

    border-radius: 4px;
    color: white;
    background-color: black;
    margin: 5px;
    padding: 6px;

    &.isToday {
      background-color: #F6F6F8;
      color: black;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .arrowIconButton {
    padding: 0;
  }

  .calendarButton {
    padding: 8px 2px 8px 8px;

    > svg {
      fill: #797C80;
    }
  }
}
