.container {
  height: 76px;
  margin: 8px 16px;

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #2e3033;
    margin-left: 8px;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .avatar {
    width: 24px;
    height: 24px;
    padding: 0;
    box-sizing: border-box;
    background-color: #494848;

    .avatarText {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 60px;
      color: white;
      font-size: 10px;
      font-weight: 500;
    }
  }
  .workingHours {
    font-size: 14px;
    font-weight: 400;
  }

  .serviceWrapper {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: #f6f6f8;
  }

  svg {
    transform: scale(0.75);

    path[fill] {
      fill: #000000 !important;
      stroke: none;
    }
    path[stroke] {
      fill: none;
      stroke: #000000 !important;
    }
  }
}

.overflowContainer {
  height: 100%;
  width: 100%;
  border-right: 3px solid #494848;
  font-weight: 400;

  .overflowHeader {
    color: white;
    background-color: #ea4e36;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 38px;
  }

  .overflowDescription {
    font-size: 16px;
    margin: 8px 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    .overflowSummary {
      display: flex;
      margin-top: 6px;

      &.compactSummary {
        max-width: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .overflowDescriptionTitle {
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      color: #2e3033;
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

