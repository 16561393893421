$column-divider-size: 1px;

.eventWrapper {
  position: absolute;
  border-radius: 4px;
  width: calc(100% - $column-divider-size);
  margin-left: $column-divider-size;
  cursor: pointer;
  z-index: 11;
  min-height: 27px;
  overflow: hidden;

  &:nth-child(2) {
    .eventContent {
      height: calc(100% - 2px);
      top: 2px;
    }
  }

  .eventContent {
    position: relative;
    box-shadow: rgba(73, 74, 78, 0.05) 0 4px 4px 0;
    top: 0;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
  }

  &.inactive {
    opacity: 0.5;
  }
}
