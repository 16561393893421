$border: 1px solid #E0E0E4;


.calendar {
  height: 100%;
  overflow: auto;

  .loading {
    height: 200px;
    grid-column: 1/-1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendar_wrapper {
    display: inline-block;
    min-width: 100%;

    .calendar_content {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      width: 100%;

      background: #F6F6F8;

      border-top: $border;
      border-left: $border;
      box-sizing: border-box;

      .day_title {
        height: 72px;
        background: white;

        border-right: $border;
        border-bottom: $border;

        display: flex;
        justify-content: center;
        align-items: center;
        color: #2E3033;
        font-size: 14px;
        font-weight: 700;
      }

      .day_content {
        border-right: $border;
        border-bottom: $border;
      }
    }
  }

}

