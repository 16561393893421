.input {
  :global {
    .MuiOutlinedInput-root {
      background-color: white;
      border-radius: 4px;

      > input {
        text-overflow: ellipsis;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #E0E0E4;
      }
    }
  }
}
