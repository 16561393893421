.container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;

  min-width: 300px;
  border-radius: 6px;
  background: white;
  box-shadow: 0px 2px 2px 0px #00000014, 0px 24px 100px -11px #00000040;

  padding: 16px 18px 36px 16px;

  &.extendedContainer {
    margin-bottom: 18px;
  }
}

.actionBar {
  justify-content: flex-end;

  .actionIcon {
    width: 18px;
    height: 18px;
  }

  .actionIconButton {
    padding: 0;
    &:last-of-type {
      margin-left: 20px;

      .actionIcon {
        fill: #c2c2c2;
      }
    }
  }
  .disabledButton {
    cursor: default;
    opacity: 0.3;
  }
}

.detailsContainer {
  margin-top: 28px;
  max-width: 324px;
  color: #2e3033;
  font-family: Fielmann-Sans;
  font-size: 13px;
  line-height: 13px;

  .appointmentType {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    margin-right: 20px;

    &.appointment {
      background-color: #00567c;
    }
    &.break {
      background-color: #dedcd7;
    }

    &.blocker {
      background-color: #b8b6ae;
    }

    &.reserve {
      background-color: #0059bf;
    }

    &.task {
      background-color: #f0fe54;
    }

    &.walkIn {
      background-color: #0059bf;
    }
  }

  .appointmentName {
    color: #2e3033;
    font-family: Fielmann-Sans;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
  }

  .appointmentDate {
    color: #2e3033;
    font-family: Fielmann-Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    margin: 16px 0 24px 36px;
  }
}

.detailsIcon {
  width: 18px;
  height: 18px;
  fill: #797c80;
}

.servicesContainer {
  margin: 28px 0;
  .servicesTitle {
    color: #000;
    font-family: Fielmann-Sans;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;

    margin-bottom: 8px;
  }
  .servicesIconButton {
    border-radius: 6px;
    background: #f6f6f8;
    color: #2e3033;
    font-family: Fielmann-Sans;
    font-size: 12px;
    font-weight: 500;
    padding: 8px 18px;
    height: 36px;

    :global {
      .MuiButton-startIcon {
        margin-left: 0;
        > svg {
          height: 20px;
        }
      }
    }
  }
}

.employeeContainer {
  .employeeTitle {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 12px;
  }
}

.dialogContainer {
  .dialogTitle {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    padding: 28px 28px 10px 28px;
  }
  .dialogDescription {
    font-family: Manrope;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #0d2027;
  }
  .deleteButton {
    background-color: #f1f1eb;
    color: black;
    margin-left: 12px;
    &:hover {
      background-color: rgba(241, 241, 235, 0.8);
    }
  }
  :global {
    .MuiPaper-root {
      max-width: 488px;
    }
    .MuiDialogContent-root {
      background-color: white;
      padding: 0 28px;
    }
    .MuiDialogActions-root {
      justify-content: flex-start;
      &.MuiDialogActions-spacing {
        padding: 32px 28px 28px 28px;
      }
    }
  }
}
