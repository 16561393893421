.content {
  :global {
    .DialogSection-root {
      .DialogSection-title {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.submitButton {
  height: 70px;
}

.warningOverwrite {
  background-color: #F8DC8C;
  padding: 4px 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 5px;
}
