.dayContainer {
  position: relative;
  height: 100%;
}

.weekViewContainer {
  overflow-y: auto;
}

.dayTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  max-width: 100px;
  height: 46px;
  background: #FFFFFF;
  color: #FFFFFF;
  border-top: 1px solid #DEDCD7;
}

.daySection {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 46px;
  background: #CBE1CD;
  color: #2E3033;
  border-left: 1px solid #FFFFFF;
}
