.peakTime {
  border-left: 4px solid #00E08E;
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 20;

  .iconsContainer {
    display: none;
  }

  &.isPeakHour {
    border-left: 4px solid #FE3C25;
    .iconsContainer {
      position: absolute;
      display: flex;
      left: 30px;
      top: 70px;
      width: 40px;
      height: 16px;
      background-color: #FE3C25;
      border-radius: 4px;

      > svg {
        width: 11px;
        margin-left: -2px;
      }
    }
  }
}
