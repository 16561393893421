.root {
  flex-wrap: wrap;

  .button {
    margin-right: 8px;
    margin-bottom: 8px;
  }

  &.singleRow {
    :global {
      .MuiButtonBase-root {
        padding: 12px;
        margin-right: 2px !important;
        margin-bottom: 0 !important;
      }
    }
    .button {
      margin-right: 2px !important;
      margin-bottom: 0;
    }
  }
}
