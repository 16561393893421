@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    src:  url('./Manrope-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    src:  url('./Manrope-VariableFont_wght.ttf') format('truetype');
}

@font-face {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    src:  url('./Manrope-VariableFont_wght.ttf') format('truetype');
}
