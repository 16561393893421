.eventsContainerOverflow {
  :global {
    .rbc-events-container {
      border-right: 2px solid #494848;

      .calendar-event {
        .rbc-event-content {
          background-color: #ffe5e2;
        }
      }
    }
  }
}
