.container {
  background: #FFFFFF;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;

  .content{
    position: relative;
  }
}
