.calendar_overview_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 100;
}

.calendar_container {
  height: calc(100vh - 118px);
  width: 100%;
}
