.timeInput {
  :global {
    .MuiOutlinedInput-root {
      background-color: white;
      border-radius: 4px;

      > input {
        text-overflow: ellipsis;
      }
    }

    .MuiInputAdornment-root {
      .MuiButtonBase-root {
        pointer-events: none;
      }
      svg, path {
        fill: #000000 !important;
      }
    }
  }
}
