$error: #FE3C25;

.overview_item {
  //font-size: 14px;
  color: #6A6B6C;

  .item_value {
    font-weight: 700;
    margin-right: 4px;
    color: #2E3033;
  }


  &.error {
    color: $error;

    .item_value {
      color: $error;
    }
  }
}
