@mixin hiddenScrollBar{
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}


.hideScroll {
  @include hiddenScrollBar;
}
